.App {
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  padding-top: 80px; /* Adjust to the height of the fixed header */
}

/* Animation for page transitions */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

/* Ensure the container takes the full page */
.page-transition {
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content {
  padding-top: 80px; /* Adjust based on the height of your header */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-image {
  width: 100px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}
